import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';

import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PartDetail } from '../../services/generated/ApiClientGenerated';
import { updatePartDetailsAction } from '../partDetails/partDetailSlice';

type PartDetailCommentProps = {
  open: boolean;
  onClose: () => void;
  row: PartDetail;
  title: string;
  confirmationButtonText: string;
  content?: string;
  placeholder?: string;
};

export const PartDetailCommentDialog: FC<PartDetailCommentProps> = ({
  open,
  row,
  onClose,
  title,
  confirmationButtonText,
  content,
  placeholder,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [note, setNote] = useState<string | undefined>(row.notes);

  const maxNoteCharacterLength = 300;

  const clearCount = () => {
    onClose();
    setTimeout(function () {
      setNote(row.notes || '');
    }, 500);
  };

  const handleSubmit = () => {
    if (!row.baseSupplierId) {
      return;
    }
    dispatch(
      updatePartDetailsAction({
        supplierId: row.supplierId!,
        partDetailId: row.partNumber!,
        body: {
          ...row,
          notes: note,
        },
      }),
    );

    onClose();
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="comment-dialog-title"
      aria-describedby="comment-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle} id="comment-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <TextField
          className={classes.root}
          error={note?.length && maxNoteCharacterLength <= note?.length ? true : false}
          autoFocus
          helperText={
            note?.length && maxNoteCharacterLength <= note?.length
              ? 'You have reached the maximum character limit'
              : `${note?.length}/${maxNoteCharacterLength}`
          }
          inputProps={{ maxLength: 300 }}
          multiline
          variant="outlined"
          margin="dense"
          id={title.toString()}
          defaultValue={content}
          placeholder={placeholder}
          type="text"
          fullWidth
          onChange={(e) => {
            if (e.target.value == '') {
              setNote(undefined);
            } else setNote(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={clearCount} color="default" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" autoFocus>
          {confirmationButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormHelperText-root': {
        textAlign: 'right',
        marginRight: theme.spacing(0),
      },
    },
    actions: {
      padding: theme.spacing(0, 3, 2),
    },
    content: {
      padding: theme.spacing(0, 3, 1),
    },
    dialogTitle: {
      padding: theme.spacing(2, 3, 0),
    },

    header: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);
